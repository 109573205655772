import * as React from 'react';
import { isCommitter } from './User';
import { Grid, Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import Login from './Login';

export default function Footer({addEventHandler}) {
  return (
    <Grid container sx={{paddingTop: "20px", paddingLeft: "10px"}}>
          <Grid item xs={11} sx={{fontWeight: "light", fontSize: "0.5em", paddingTop: "5px"}}>
          © 2024
          </Grid>
          <Grid item xs={1} sx={{justifyContent: "right", display: "flex"}}>
            {isCommitter() && (
              <Tooltip title="Create New Recipe">
                <IconButton onClick={addEventHandler}>
                  <NoteAddIcon />
                </IconButton>
              </Tooltip>
            )}
            <Login />
          </Grid>
    </Grid>
  );
}