import { Grid, TextField } from "@mui/material";
import React from "react";

function Image({ image }) {

	const handleDragStart = (event, text) => {
		event.dataTransfer.setData('text/plain', text);
	};

	return (
		<Grid container spacing={0} style={{ paddingBottom: "20px"}}>
		<Grid item xs={12} >
		  <img alt='' src={image.src} width={"100px"} onDragStart={(event) => handleDragStart(event, image.file)}/>
		</Grid>
		<Grid item xs={12} 
        draggable
        onDragStart={(event) => handleDragStart(event, image.file)}
      >
       {image.file}
		  
		</Grid>
		</Grid>
	);
}

export default Image;