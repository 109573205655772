// direct - no kong
// export const rest_endpoint = "http://192.168.2.119:3081/api/recipes"
//const fqdn = "192.168.2.119"
//const fqdn = "localhost:8080"
// Via KONG

let fqdn = "gutfounded.ca"
export let rest_endpoint = `https://${fqdn}/api/recipes/api/recipes`
export let tiles_endpoint = `https://${fqdn}/api/recipes/api/tiles`
export let keycloak_proxy = `https://${fqdn}/api/recipes/`
export let image_endpoint = `https://${fqdn}/api/images/`
export let keycloak_endpoint = `https://${fqdn}:8443`

export const upload_images_width = 1000

export const banner_title = "Gut Founded"

export function setHostName(host) {
   if (host === "192.168.2.119" || host === "192.168.2.18")  {
    fqdn = "192.168.2.119"
    rest_endpoint = `https://${fqdn}/api/recipes/api/recipes`
    tiles_endpoint = `https://${fqdn}/api/recipes/api/tiles`
    keycloak_proxy = `https://${fqdn}/api/recipes/`
    image_endpoint = `https://${fqdn}/api/images/`
    keycloak_endpoint = `https://${fqdn}:8443`
   }

   if (host === "localhost")  {
      fqdn = "192.168.2.119"
      rest_endpoint = `http://${fqdn}/api/recipes/api/recipes`
      tiles_endpoint = `http://${fqdn}/api/recipes/api/tiles`
      keycloak_proxy = `http://${fqdn}/api/recipes/`
      image_endpoint = `http://${fqdn}/api/images/`
      keycloak_endpoint = `https://${fqdn}:8443`
     }
  
}
//export const rest_endpoint = "http://localhost:3081/api/recipes"

// direct - no Kong
//export const image_endpoint = "http://192.168.2.119:8080/"

// Via KONG


//export const image_endpoint = "http://localhost:8080/"
// export const rest_endpoint = "https://5juw3472ua.execute-api.us-east-1.amazonaws.com/items"