import './App.css';
import { Routes, Route } from 'react-router-dom';
import Home from './Home';
import Settings from './Settings';
import { setHostName } from './config';

function App() {

  const backendHostname = window.location.hostname;
  setHostName(backendHostname)

  return (
    <div>
      <Routes>
        <Route path='/' element={<Home/>} />
        <Route path="/settings" element={<Settings/>} />
      </Routes>
    </div>
  );
}

export default App;
