import { useState, useRef, useEffect } from "react";
import "./App.css";
import { TextField, Grid, Button } from "@mui/material";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { rest_endpoint } from "./config";
import { getAccessToken } from "./User";
import { image_endpoint, upload_images_width } from "./config";

import { HtmlEditor, Image, Inject, Link, QuickToolbar, Table, RichTextEditorComponent, Toolbar } from '@syncfusion/ej2-react-richtexteditor';
import '../node_modules/@syncfusion/ej2-base/styles/material.css';
import '../node_modules/@syncfusion/ej2-icons/styles/material.css';
import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
import '../node_modules/@syncfusion/ej2-lists/styles/material.css';
import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
import '../node_modules/@syncfusion/ej2-richtexteditor/styles/material.css';
import * as React from 'react';

export default function EditorRichText({ editSuccess, cancelSuccess, oldRecipe }) {

    const initRecipe = oldRecipe ? oldRecipe : {title:"", body:"", category:""}
    const [recipe, setRecipe] = useState(initRecipe);
    let tempFileInsert = ""
    const [titleInputError, setTitleInputError] = useState('')
    const inputReference = useRef(null);
    let rteObj;

    const authStr = 'Bearer '.concat(getAccessToken()); 

    const toolbarSettings = {
        items: ['Bold', 'Italic', 'Underline', 'StrikeThrough',
            'FontName', 'FontSize', 'FontColor', 'BackgroundColor',
            'LowerCase', 'UpperCase', '|',
            'Formats', 'Alignments', 'OrderedList', 'UnorderedList',
            'Outdent', 'Indent', 'CreateTable', '|',
            'CreateLink', 'Image', '|', 'ClearFormat', 'Print',
            'SourceCode', 'FullScreen', '|', 'Undo', 'Redo']
    };
    const quickToolbarSettings = {
        image: ['Replace', 'Align', 'Caption', 'Remove', 'InsertLink', 'OpenImageLink', '-', 'EditImageLink', 'RemoveImageLink', 'Display', 'AltText', 'Dimension']
    };

    const insertImageSettings = {
        path : `${image_endpoint}upload`
    }

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setRecipe((prevProps) => ({
      ...prevProps,
      [name]: value
    }));
  };

  const handleSubmit = (event) => {
    let errorMessage = ""
    event.preventDefault();
    if (recipe.title.trim() === '') {
      setTitleInputError("Title can't be blank");
      return;
    }
    recipe.body = rteObj.value;

    const authStr = 'Bearer '.concat(getAccessToken()); 

    const method = oldRecipe ? 'PUT' : 'POST';
    const endpoint = oldRecipe ? `${rest_endpoint}/${oldRecipe._id}` : rest_endpoint;

    const requestOptions = {
        method,
        headers: { 'Authorization': authStr, 'Content-Type': 'application/json'},
        body: JSON.stringify(recipe)
    };
    fetch(endpoint, requestOptions)
      .then(response => {
        if (response.status === 401) {
          errorMessage = `You're login expired, please login and try saving again.`
        } else if (! response.ok) {
          errorMessage = `Save recipe failed with error code: ${recipe.status}`
        }
      })
      .then(data => {
        console.log(data)
        if (errorMessage === "") {
          editSuccess(`Recipe ${recipe.title} saved!`)
        } else {
          toast(errorMessage)
        }})
  };

  const handleCancel = (event) => {
    let errorMessage = ""
    event.preventDefault();
    cancelSuccess()
  };

  const handleActionComplete = (args) => {
    if (args.requestType === 'Images') {
      const imgElement = args.elements[0];
      const fileName = `${imgElement.alt}`;
      const fileExtension = fileName.split('.').pop();
      if (tempFileInsert !== "") {
         const newSrc = `http://192.168.2.119/api/images/files/${tempFileInsert}`;
         // Update the image src and alt attributes
         imgElement.src = newSrc;
         imgElement.alt = `${tempFileInsert}`;
      }
      tempFileInsert = "";
    }
  };

  const handleImageSelected = async (args) => {
    const file = args.filesData[0];
    tempFileInsert = file.name;
    console.log(file.name)
    console.log(file.rawFile)
    handleUpload(file.rawFile)
    // Construct the new src URL
    const newSrc = `http://192.168.2.119/api/images/files/${file.name}`;
    file.url = newSrc;
    file.alt = `${file.name}`;
  }

  const handleUpload = async (file) => {  
        var formdata = new FormData(); 
        formdata.append("file", file);

        var requestOptions = {
          method: 'POST',
          body: formdata,
          redirect: 'follow'
        };

        fetch(`${image_endpoint}upload`, requestOptions)
          .then(response => {
            if (! response.ok) {
              toast(`Failed to add recipe image! Error code: ${response.status}`)
            } 
          }) 
          .then(result => console.log("Done uploading!!"))
          .catch(error => console.log('error', error));
  };

  
  const transformHtmlUrls = (htmlString) => {
    // Define the regex pattern to match URLs with the specified path, including http and https
    const urlPattern = /https?:\/\/[^"]*\/api\/images\/files\/[^"]*/g;
    
    // Use the replace method to replace matched URLs with the new base URL
    const transformedHtml = htmlString.replace(urlPattern, (match) => {
      // Extract the file name and extension from the old URL
      const fileName = match.split('/').pop();
      // Construct the new URL using the new base URL and the extracted file name
      const newUrl = `${image_endpoint}files/${fileName}`;
      return newUrl;
    });
  
    return transformedHtml;
  }

  useEffect(() => {
    if (titleInputError !== '') {
      inputReference.current.focus();
    } 
  })

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <h1 className="new-recipe-header">Create Recipe</h1>
      </Grid>
      <Grid item xs={6}>
        <Button onClick={handleSubmit} type="Submit" variant="contained">Save</Button>
        <Button onClick={handleSubmit} type="reset" variant="contained" sx={{marginLeft: "3px"}}>Cancel</Button>
      </Grid>
      <Grid item xs={12}>
        <TextField sx={{width: "100%"}} title="Title" inputRef={inputReference} error={titleInputError} helperText={titleInputError} required label="Title" variant="outlined" onChange={handleInputChange} name="title" defaultValue={recipe.title} />
      </Grid>
      <Grid item xs={12}>
        <TextField sx={{width: "100%"}} label="Category" variant="outlined" multiline onChange={handleInputChange} name="category" defaultValue={recipe.category}/>
      </Grid>
      <Grid item xs={12}>
        <RichTextEditorComponent  actionComplete={handleActionComplete} ref={(richtexteditor) => { rteObj = richtexteditor; }} imageSelected={handleImageSelected} height={450} toolbarSettings={toolbarSettings} quickToolbarSettings={quickToolbarSettings} value={transformHtmlUrls(recipe.body)}>
          <Inject services={[Toolbar, Image, Link, HtmlEditor, QuickToolbar, Table]} name="body"/>
        </RichTextEditorComponent>
      </Grid>
      <Grid item xs={6}>
        <Button onClick={handleSubmit} type="Submit" variant="contained">Save</Button>
        <Button onClick={handleSubmit} type="reset" variant="contained" sx={{marginLeft: "3px"}}>Cancel</Button>
      </Grid>
    </Grid>
  );
}
