import './App.css';
import RecipeCard from "./recipeCard";
import Grid from '@mui/material/Grid'
 
export default function RecipeList({ recipeList, selectedCard, selectedCategory }) {

  recipeList.sort((a, b) => {
    const titleA = a.title.toLowerCase();
    const titleB = b.title.toLowerCase();
  
    if (titleA < titleB) {
      return -1;
    }
    if (titleA > titleB) {
      return 1;
    }
    return 0;
  });

  return (
    <Grid container spacing={2}>
      {recipeList.map((recipe) => (
        <RecipeCard recipe={recipe} selectedCard={selectedCard} selectedCategory={selectedCategory}/>
      ))}
    </Grid>
  )
}
