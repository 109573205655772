import React, { useEffect, useState, useRef } from "react";
import "./App.css";
import Deleter from "./Deleter";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { Grid, IconButton, Tooltip, Switch, FormControlLabel } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import { isCommitter } from './User';
import { image_endpoint } from './config';

export default function Viewer({ recipe, deleteSuccess, switchToEditMode }) {
  const [keepAwake, setKeepAwake] = useState(false); // State to track if the screen should stay awake
  const wakeLockRef = useRef(null); // Ref to store the wake lock instance

  useEffect(() => {
    const handleWakeLock = async () => {
      if (keepAwake && 'wakeLock' in navigator) {
        try {
          // Request wake lock only if not already active
          if (!wakeLockRef.current) {
            wakeLockRef.current = await navigator.wakeLock.request('screen');
            console.log("Screen wake lock is active.");

            // Handle the release event in case it's released automatically (e.g., due to system constraints)
            wakeLockRef.current.addEventListener('release', () => {
              console.log("Screen wake lock was automatically released.");
              wakeLockRef.current = null; // Reset the ref
            });
          }
        } catch (err) {
          console.error(`${err.name}, ${err.message}`);
        }
      } else if (wakeLockRef.current) {
        // If wake lock is active, release it when not needed
        wakeLockRef.current.release();
        wakeLockRef.current = null;
        console.log("Screen wake lock has been released.");
      }
    };

    handleWakeLock();

    // Cleanup: release wake lock on component unmount
    return () => {
      if (wakeLockRef.current) {
        wakeLockRef.current.release();
        wakeLockRef.current = null;
      }
    };
  }, [keepAwake]); // Only trigger when keepAwake changes

  const handleSwitchChange = (event) => {
    setKeepAwake(event.target.checked); // Update the state based on switch input
  };

  const transformHtmlUrls = (htmlString) => {
    const urlPattern = /https?:\/\/[^"]*\/api\/images\/files\/[^"]*/g;
    const transformedHtml = htmlString.replace(urlPattern, (match) => {
      const fileName = match.split('/').pop();
      const newUrl = `${image_endpoint}files/${fileName}`;
      return newUrl;
    });
    return transformedHtml;
  };

  return (
    <Grid container spacing={0}>
      <Grid item xs={12} sm={11}>
        <ReactMarkdown children={recipe.title} className="title" />
      </Grid>
      {isCommitter() && (
        <Grid item xs={3} sm={1} sx={{ justifyContent: "right", display: "flex" }}>
          <Deleter recipe={recipe} deleteSuccess={deleteSuccess} />
          <Tooltip title="Edit Recipe">
            <IconButton onClick={switchToEditMode} sx={{ height: "40px" }}>
              <EditIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      )}
      <Grid item xs={12} sx={{ marginLeft: "20px" }}>
        <div dangerouslySetInnerHTML={{ __html: transformHtmlUrls(recipe.body) }} />
      </Grid>

      {/* Add a switch to control screen awake functionality */}
      <Grid item xs={12} sx={{ marginLeft: "20px" }}>
        <FormControlLabel
          control={<Switch checked={keepAwake} onChange={handleSwitchChange} />}
          label="Keep Screen Awake"
        />
      </Grid>
    </Grid>
  );
}
