import "./App.css";
import { rest_endpoint } from "./config";
import { useEffect, useState } from "react";
import Editor from "./Editor";
import EditorRichText from "./EditorRichText";
import Viewer from "./Viewer";


export default function Recipe({ recipeId, deleteSuccess }) {
    const [recipe, setRecipeData] = useState({title: "loading", category: "loading", body: "loading"});
    const [editMode, setEditMode] = useState(false)

    useEffect(() => {
        fetch(`${rest_endpoint}/${recipeId}`)
        .then(response => response.json())
        .then(data => { setRecipeData(data); })
    }, [editMode]);

    const switchToEditMode = () => setEditMode(true);
    const editSuccess = () => setEditMode(false);

    return (<>
      { !editMode && <Viewer recipe={recipe} deleteSuccess={deleteSuccess} switchToEditMode={switchToEditMode} /> }
      { editMode && <EditorRichText editSuccess={editSuccess} oldRecipe={recipe} /> }
    </>);
}
