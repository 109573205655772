
import { isSessionExpired } from './User';
import LoginIcon from '@mui/icons-material/Login';
import IconButton from '@mui/material/IconButton';
import LogoutIcon from '@mui/icons-material/Logout';
import { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, TextField, Tooltip } from '@mui/material';
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { keycloak_proxy } from "./config";
import { setCommenter, setCommitter, inactivateUser, setAccessToken, setRefreshToken, setActive, setUsername, setExpiresIn, setLoginTimestamp, getUsername } from "./User";

export default function Login() {
    const [showLoginDialog, setShowLoginDialog] = useState(false);
    const [showLogin, setShowLogin] = useState(isSessionExpired());
    const loginEvent = () => setShowLoginDialog(true)
    const logoutEvent = () => {
        toast.success(`${getUsername()} logged out`)
        inactivateUser()
        setShowLogin(true)

    }
    const [username, setUser] = useState('')
    const [password, setPassword] = useState('')

    const handleCancel = () => {
        setShowLoginDialog(false)
    }

    const handleUsernameChange = (event) => {
        const { name, value } = event.target;
        setUser(value.toLowerCase())
    };

    const handlePasswordChange = (event) => {
        const { name, value } = event.target;
        setPassword(value)
    };

    const handleLogin = () => {
        var requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json'},
            body: JSON.stringify({"username": username, "password": password})
          };
      
          fetch(`${keycloak_proxy}/login`, requestOptions)
            .then(response => {
              if (! response.ok) {
                toast(`Failed to login! Error code: ${response.status}`)
              }
              return response.json()
            }) 
            .then(result => {
                if (result.error) {
                    toast.error(`Invalid credentials, please try again`)
                } else {
                    setAccessToken(result.access_token)
                    setRefreshToken(result.refresh_token)
                    getRoles(result)
                    setActive()
                    setExpiresIn(result.expires_in)
                    console.log(result)
                    setLoginTimestamp(Date.now())
                    setShowLoginDialog(false)
                    setShowLogin(false)
                }
            })
            .catch(error => console.log('error', error));
        }


    const getRoles = (result) => {
        const authStr = 'Bearer '.concat(result.access_token); 

        const requestOptions = {
            method: 'GET',
            headers: { 'Authorization': authStr, 'Content-Type': 'application/json'}
        };
    
        fetch(`${keycloak_proxy}/access`, requestOptions)
          .then(response => {
            if (! response.ok) {
              toast.error(`Failed to get details! Error code: ${response.status}`)
            }
            return response.json()
          }) 
          .then(result => {
              if (result.error) {
                  toast.error(`Invalid details`)
              } else {
                  setCommitter(result.realm_access.roles.includes("editor"))
                  setCommenter(result.realm_access.roles.includes("commenter"))
                  setUsername(result.preferred_username)
                  toast.success(`${getUsername()} logged in`)
              }
          })
          .catch(error => console.log('error', error));
        }

    return (<>{showLogin && (
        <Tooltip title="Login">
          <IconButton onClick={loginEvent} >
            <LoginIcon />
          </IconButton>
        </Tooltip>
        )}
        {! showLogin && (
          <Tooltip title="Logout">
            <IconButton onClick={logoutEvent} >
              <LogoutIcon />
            </IconButton>
          </Tooltip>
        )}
        <Dialog open={showLoginDialog} onClose={handleCancel} role="alertDialog">
      <DialogTitle>
        {`Login`}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{marginTop: "10px", marginBottom: "10px"}}>
          <Grid item xs={12}>
            <TextField sx={{width: "200px"}} title="username" onChange={handleUsernameChange} required label="Username" variant="outlined" name="username" />
          </Grid>
          <Grid item xs={12}>
            <TextField sx={{width: "200px"}} title="password" type="password" onChange={handlePasswordChange}  required label="Password"  variant="outlined" name="password"/>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} autoFocus>Cancel</Button>
        <Button onClick={handleLogin} >Login</Button>
      </DialogActions>
    </Dialog>
    </>
  );
}
