import { useState, useRef, useEffect } from "react";
import "./App.css";
import { TextField, Grid, Button } from "@mui/material";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { rest_endpoint } from "./config";
import { getAccessToken } from "./User";
import { image_endpoint, upload_images_width } from "./config";

import { HtmlEditor, Image, Inject, Link, QuickToolbar, Table, RichTextEditorComponent, Toolbar } from '@syncfusion/ej2-react-richtexteditor';
import '../node_modules/@syncfusion/ej2-base/styles/material.css';
import '../node_modules/@syncfusion/ej2-icons/styles/material.css';
import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
import '../node_modules/@syncfusion/ej2-lists/styles/material.css';
import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
import '../node_modules/@syncfusion/ej2-richtexteditor/styles/material.css';
import * as React from 'react';

export default function Settings() {

    const [settings, setSettings] = useState({title:''});

    const handleSubmit = (event) => {
        let errorMessage = ""
        event.preventDefault();
        if (settings.title.trim() === '') {
          console.log("Can't be blank")
          return;
        }
    
        const method = 'PUT';
        const endpoint = rest_endpoint;
    
        const requestOptions = {
            method,
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(settings)
        };
        fetch(endpoint, requestOptions)
          .then(response => {
            if (response.status === 401) {
              errorMessage = `You're login expired, please login and try saving again.`
            } else if (! response.ok) {
              errorMessage = `Save settings failed with error code`
            }
          })
          .then(data => {
            console.log(data)
            if (errorMessage === "") {
              toast(`Settngs saved!`)
            } else {
              toast(errorMessage)
            }})
      };
 
      const handleInputChange = (event) => {
        const { name, value } = event.target;
        setSettings((prevProps) => ({
          ...prevProps,
          [name]: value
        }));
      };
    
  return (
    <Grid container spacing={2}>
      <h1 className="new-recipe-header">Settings</h1>
      <Grid item xs={12}>
        <TextField  onChange={handleInputChange} sx={{width: "100%"}} title="Website Title" name="title" defaultValue={""}  required label="Website Title" variant="outlined"/>
      </Grid>
      <Grid item xs={12}>
        <TextField sx={{width: "100%"}} title="Website Description" name="description" defaultValue={""}  required label="Description" variant="outlined"/>
      </Grid>
      <Grid item xs={12}>
        <TextField sx={{width: "100%"}} title="Banner Color" name="banner_color" defaultValue={"white"}  required label="Banner Color" variant="outlined"/>
      </Grid>
      <Grid item xs={6}>
        <Button onClick={handleSubmit} type="Submit" variant="contained">Save</Button>
        <Button type="reset" variant="contained" sx={{marginLeft: "3px"}}>Cancel</Button>
      </Grid>
    </Grid>
  );
}
