import { Grid } from "@mui/material";
import { useCallback, useState } from "react";
import DropBox from "./DropBox";
import ShowImage from "./ShowImage";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { image_endpoint, upload_images_width } from "./config";

export default function ImageDropBox() {

    const [images, setImages] = useState([]);

    const onDrop = useCallback((acceptedFiles) => {
        acceptedFiles.map((file, index) => {
            const reader = new FileReader();

            reader.onload = function (e) {
                setImages((prevState) => [
                    ...prevState,
                    { id: index, src: e.target.result, file: file.name },
                ]);
            
                console.log("submitting image")
                handleUpload({src: e.target.result, file: file.name})
            };
            reader.readAsDataURL(file);
            return file;
        });
    }, []);


    const handleUpload = async (file) => {  
        const img = new Image();
        img.onload = function () {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          canvas.width = upload_images_width; // Adjust the desired width
          canvas.height = (img.height / img.width) * canvas.width;
          ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
  
          // Convert canvas to blob
          canvas.toBlob((blob) => {
            // Create a FormData object to send the blob to the server
            
            const newfile = new File([blob], file.file, { type: "image/png" });
            var formdata = new FormData();
            formdata.append("file", newfile);
    
            var requestOptions = {
              method: 'POST',
              body: formdata,
              redirect: 'follow'
            };
    
            fetch(`${image_endpoint}upload`, requestOptions)
              .then(response => {
                if (! response.ok) {
                  toast(`Failed to add recipe image! Error code: ${response.status}`)
                }
              }) 
              .then(result => console.log("Done uploading!!"))
              .catch(error => console.log('error', error));
            })
        };
        img.src = file.src;
      };
  

    return (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DropBox onDrop={onDrop} />
          </Grid>
          <Grid item xs={12}>
            <ShowImage images={images}/>
          </Grid>
        </Grid>
    );
}