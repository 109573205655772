import React, { useState, useRef, useEffect } from "react";
import "./App.css";
import { TextField, Grid, Button, Tooltip, IconButton} from "@mui/material";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { rest_endpoint } from "./config";
import { getAccessToken } from "./User";
import ImageDropBox from "./ImageDropBox";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { MarkdownComponents } from "./MarkdownComponents";
import PreviewIcon from '@mui/icons-material/Preview';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

export default function Editor({ editSuccess, oldRecipe }) {

  const initRecipe = oldRecipe ? oldRecipe : {title:"", body:"", category:""}
  const [recipe, setRecipe] = useState(initRecipe);
  const [previewMarkdown, setPreviewMarkdown] = useState(false);
  const [switchToViewMarkdownHelp, setSwitchToViewMarkdownHelp] = useState(false);

  const [titleInputError, setTitleInputError] = useState('')
  const inputReference = useRef(null);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setRecipe((prevProps) => ({
      ...prevProps,
      [name]: value
    }));
  };

  const handleSubmit = (event) => {
    let errorMessage = ""
    event.preventDefault();
    if (recipe.title.trim() === '') {
      setTitleInputError("Title can't be blank");
      return;
    }

    const authStr = 'Bearer '.concat(getAccessToken()); 

    const method = oldRecipe ? 'PUT' : 'POST';
    const endpoint = oldRecipe ? `${rest_endpoint}/${oldRecipe._id}` : rest_endpoint;

    const requestOptions = {
        method,
        headers: { 'Authorization': authStr, 'Content-Type': 'application/json'},
        body: JSON.stringify(recipe)
    };
    fetch(endpoint, requestOptions)
      .then(response => {
        if (response.status === 401) {
          errorMessage = `You're login expired, please login and try saving again.`
        } else if (! response.ok) {
          errorMessage = `Save recipe failed with error code: ${recipe.status}`
        }
      })
      .then(data => {
        console.log(data)
        if (errorMessage === "") {
          editSuccess(`Recipe ${recipe.title} saved!`)
        } else {
          toast(errorMessage)
        }})
  };

  useEffect(() => {
    if (titleInputError !== '') {
      inputReference.current.focus();
    } 
  })

  const handleBodyDrop = (event) => {
    event.preventDefault();
    const text = event.dataTransfer.getData('text/plain');
    setRecipe((prevProps) => ({
      ...prevProps,
      body: `${recipe.body}\n![width:200, caption:'place holder'](${text})`
    }));
  };

  
  const switchToPreviewMode = () => setPreviewMarkdown(true)
  const switchToTextMode = () => setPreviewMarkdown(false)
  const openMarkdownHelp = () => setSwitchToViewMarkdownHelp(true)
  const closeMarkdownHelp = () => setSwitchToViewMarkdownHelp(false)

  return (
    <Grid container spacing={2}>
      <h1 className="new-recipe-header">Create Recipe</h1>
      <Grid item xs={12}>
        <TextField sx={{width: "100%"}} title="Title" inputRef={inputReference} error={titleInputError} helperText={titleInputError} required label="Title" variant="outlined" onChange={handleInputChange} name="title" defaultValue={recipe.title} />
      </Grid>
      <Grid item xs={12}>
        <TextField sx={{width: "100%"}} label="Category" variant="outlined" multiline onChange={handleInputChange} name="category" defaultValue={recipe.category}/>
      </Grid>
      <Grid item xs={12}>
        <TextField sx={{width: "100%"}} label="Recipe"  onDrop={handleBodyDrop} multiline onChange={handleInputChange} value={recipe.body} defaultValue={recipe.body} name="body"/>
      </Grid>
      <Grid item xs={12}>
        <Tooltip title="Preview Markdown">
          <IconButton onClick={switchToPreviewMode}>
            <PreviewIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Markdown Help">
          <IconButton onClick={openMarkdownHelp}>
            <HelpOutlineIcon />
          </IconButton>
        </Tooltip>
      </Grid>
      <Dialog open={previewMarkdown} onClose={switchToTextMode} role="alertDialog">
      <DialogTitle>
        {`${recipe.title}`}
      </DialogTitle>
      <DialogContent>
        <ReactMarkdown children={recipe.body} components={MarkdownComponents} />
      </DialogContent>
      <DialogActions>
        <Button onClick={switchToTextMode} autoFocus>Close</Button>
      </DialogActions>
    </Dialog>

    <Dialog open={switchToViewMarkdownHelp} onClose={closeMarkdownHelp} role="alertDialog">
      <DialogTitle>Markdown Help</DialogTitle>
      <DialogContent>
      <Grid container spacing={2}>
        <Grid xs={6} sx={{paddingTop: "25px"}}>
            <p>"Markdown"</p>
            <p>- Bullet</p>
            <p>1. Ordered</p>
            <p>1. Ordered</p>
            <p style={{paddingTop: "3px"}}>*Recipe*</p>
            <p>**Bold**</p>
            <p style={{paddingTop: "10px"}}># Notes</p>
            <p style={{paddingTop: "20px"}}>## Notes</p>
            <p style={{paddingTop: "18px"}}>### Notes</p>
            <p style={{paddingTop: "10px"}}>#### Notes</p>
            <p style={{paddingTop: "18px"}}>![width:100, caption:'Hi'](filename)"]</p>
        </Grid>
        <Grid xs={6} sx={{paddingTop: "25px"}}>
          <ReactMarkdown children={"Rendered\n - Bullet \n 1. Ordered\n\n 1. Ordered \n\n*Recipe* \n\n**Bold** \n\n # Notes \n\n ## Notes \n\n ### Notes \n\n #### Notes \n\n ![width:100, caption:'Hi'](IMG_20210101_143557.jpg)"} components={MarkdownComponents} />
        </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeMarkdownHelp} autoFocus>Close</Button>
      </DialogActions>
    </Dialog>

      <Grid item xs={6}>
        <Button onClick={handleSubmit} type="Submit" variant="contained">Save</Button>
        <Button onClick={handleSubmit} type="reset" variant="contained">Cancel</Button>
      </Grid>
      <Grid item xs={12}>
        <ImageDropBox/>
      </Grid>
    </Grid>
  );
}
