import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import Grid from '@mui/material/Grid';
import { MarkdownComponents } from './MarkdownComponents';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';

export default function RecipeCard({ recipe, selectedCard, selectedCategory }) {
  
  const selectedRecipe = (e) => {
    if (recipe.type === "recipe") {
      selectedCard(recipe._id)
    } else {
      selectedCategory(recipe.title)
    }
  }

  return (
    <Grid item xs={12} sm={6} md={4} lg={3}>
    <Card className="recipe-card">
      <CardActionArea id={recipe.id} onClick={selectedRecipe} >
        <CardContent sx={{paddingBottom:"10px"}}>
          <Typography gutterBottom variant="h5" component="div">
            <ReactMarkdown children={recipe.title} components={MarkdownComponents}/>
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
    </Grid>
  );
}