import { image_endpoint } from "./config"


export const MarkdownComponents = {
    p: paragraph => {
        const { node } = paragraph
          
          if (node.children[0].tagName === "img") {
            const image = node.children[0]
            console.log(image.properties.alt)
            const metastring = image.properties.alt

            let regex = /width:(\d+)/;
            let match = metastring.match(regex);
            let widthValue = "auto"
            if (match) {
              widthValue = match[1];
              console.log(widthValue); // Output: 345
            } 

            regex = /caption:'([^']*)'/;
            match = metastring.match(regex);
            let caption = ""
            if (match) {
                caption = match[1];
            }

            regex = /align:'([^']*)'/;
            match = metastring.match(regex);
            let align = ""
            if (match && match[1] === 'right') {
                align = 'right';
            }

            return (<>
              <img
                src={`${image_endpoint}/files/${image.properties.src}`}
                width={widthValue}
                alt={image.properties.alt}
                align={align}
              />
              <p className="caption">{caption}</p>
              </>
            )
          }
        return <p>{paragraph.children}</p>
      },
}
