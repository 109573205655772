



export const isCommitter = () => ! isSessionExpired() && (localStorage.getItem("recipe_user_committer_role") === "true")
export const isCommenter = () => isActive() && (localStorage.getItem("recipe_user_commenter_role") === "true")
export const setCommitter = (isCommitter) => localStorage.setItem("recipe_user_committer_role", isCommitter) 
export const setCommenter = (isCommenter) => localStorage.setItem("recipe_user_commenter_role", isCommenter) 
export const setAccessToken = (token) => localStorage.setItem("recipe_access", token)
export const setRefreshToken = (token) => localStorage.setItem("recipe_refresh", token)
export const setUsername = (username) => localStorage.setItem("recipe_username", username)
export const getRefreshToken = () => localStorage.getItem("recipe_refresh")
export const getAccessToken = () => localStorage.getItem("recipe_access")
export const getUsername = () => localStorage.getItem("recipe_username")
export const setActive = () => localStorage.setItem("recipe_user_active", true)
export const setInactive = () => localStorage.setItem("recipe_user_active", false)
export const isActive = () => (localStorage.getItem("recipe_user_active") === "true")
export const setExpiresIn = (sessionTime) => localStorage.setItem("recipe_session_time", sessionTime)
export const getExpiresIn = () => localStorage.getItem("recipe_session_time") ? 1000 * localStorage.getItem("recipe_session_time") : 0
export const setLoginTimestamp = (ts) => localStorage.setItem("recipe_login_ts", ts)
export const getLoginTimestamp = () => localStorage.getItem("recipe_login_ts") ? + localStorage.getItem("recipe_login_ts") : 0
export const isSessionExpired = () => (getLoginTimestamp() + getExpiresIn()) - Date.now() < 0
export const inactivateUser = () => {
    setExpiresIn(0)
    setInactive()
    setCommitter(false)
    setCommenter(false)
    setAccessToken("")
    setRefreshToken("")
    setUsername("")
    setLoginTimestamp(0)
}
