import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Button, IconButton, Tooltip } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {useState} from "react";
import 'react-toastify/dist/ReactToastify.css';
import { rest_endpoint } from "./config";
import { toast } from 'react-toastify';
import { getAccessToken } from './User';

function Deleter({recipe, deleteSuccess}) {

  const [open, setOpen] = useState(false)

  const deleteEvent = () => {
    setOpen(true)
  }

  const handleDisagree = () => {
    setOpen(false);
  };

  const handleAgree = () => {
    let errorMessage = ""
    const authStr = 'Bearer '.concat(getAccessToken()); 
    const requestOptions = {
      method: 'DELETE',
      headers: { 'Authorization': authStr },
    };
    fetch(`${rest_endpoint}/${recipe._id}`, requestOptions)
      .then(response => {
        if (! response.ok) {
           errorMessage = `Failed to delete recipe! Error code: ${response.status}`
        }
      })
      .then(data => {
        if (errorMessage === "") {
          deleteSuccess(`Recipe ${recipe.title} deleted successfully!`)
        } else {
          toast(errorMessage)
        }})
  };

  return ( <>
    <Tooltip title="Delete Recipe">
      <IconButton onClick={deleteEvent} sx={{height: "40px"}}>
        <DeleteForeverIcon />
      </IconButton>
    </Tooltip>
    <Dialog open={open} onClose={handleDisagree} role="alertDialog">
      <DialogTitle>
        {`Delete Recipe ${recipe.title}`}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Once the recipe has been deleted this can not be undone.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDisagree} autoFocus>Disagree</Button>
        <Button onClick={handleAgree} >Agree</Button>
      </DialogActions>
    </Dialog>
    </>)
}

export default Deleter;