import "./App.css";
import { useState } from 'react';
import RecipeList from './RecipeList';
import { Breadcrumbs, Grid, TextField } from "@mui/material";
import Recipe from './Recipe';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from "@mui/material/InputAdornment";
import Footer from "./Footer";
import { image_endpoint, rest_endpoint, tiles_endpoint, banner_title } from "./config";
import { banner_color, breadcrumb_background, breadcrumb_text } from "./theme";
import EditorRichText from "./EditorRichText";
import { useEffect } from 'react';
import { emphasize, styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import HomeIcon from '@mui/icons-material/Home';

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor =
    theme.palette.mode === 'light'
      ? theme.palette.grey[100]
      : theme.palette.grey[800];
  return {
    backgroundColor,
    height: theme.spacing(3),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    '&:hover, &:focus': {
      backgroundColor: emphasize(backgroundColor, 0.06),
    },
    '&:active': {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
  };
});


export default function Home() {
    const [searchTable, setSearchTable] = useState(true)
    const [recipePage, setRecipePage] = useState(false)
    const [addPage, setAddPage] = useState(false)
    const [recipeId, setRecipeId] = useState("")
    const [recipeList, setList] = useState([])
    const [category, setCategory] = useState("")
    const [recipeTitle, setRecipeTitle] = useState("")
    const [searchText, setSearchText] = useState("")

    const handleSearchChange = (event) => {
      const { value } = event.target;
      setSearchText(value)
    };

    const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
        fetch(`${rest_endpoint}/search?text=${searchText}`)
        .then(response => response.json())
        .then(data => { 
          setList(data);
        });
        setSearchTable(true)
        setRecipePage(false)
        setAddPage(false)
      }
    };

    const selectedCategory = (cat) => {
      const encodedCat = encodeURIComponent(cat);
      fetch(`${tiles_endpoint}/${encodedCat}`) 
      .then(response => response.json())
      .then(data => { 
        setList(data);
      });
      setCategory(cat)
      setSearchTable(true)
      setRecipePage(false)
      setAddPage(false)
    };

    const addEventHandler = (e) => {
      setSearchTable(false)
      setRecipePage(false)
      setAddPage(true)
    }
  
    const selectEvent = (id) => {
      setRecipeId(id)
      setRecipeTitle(recipeList.find(item => item._id === id).title)
      setSearchTable(false)
      setRecipePage(true)
      setAddPage(false)
    } 
  
    const saveSuccessEvent = (title) => {
      setSearchTable(true)
      setRecipePage(false)
      setAddPage(false)
      toast(title)
    }

    const cancelSuccessEvent = () => {
      setSearchTable(true)
      setRecipePage(false)
      setAddPage(false)
    }

    const deleteSuccess = (title) => {
      setSearchTable(true)
      setRecipePage(false)
      setAddPage(false)
      toast(title)
    }

    useEffect(() => {
        fetch(tiles_endpoint)
        .then(response => response.json())
        .then(data => { 
          setList(data);
        });
    }, []);
    return <>
      <Grid container >
      <Grid item xs={12}>
        <Grid container className="banner" sx={{paddingTop: "15px", paddingLeft: "10px", backgroundColor: banner_color}}>
          <Grid item xs={12} sm={6} md={8} sx={{fontWeight: "bold", fontSize: "1.5em"}} className="title-text">
            { banner_title }
          </Grid>
          <Grid item xs={10} sm={6} md={4} >
            <div className="search">
             <TextField InputProps={{
               endAdornment: ( <InputAdornment position = "end"><SearchIcon /></InputAdornment> )
             }}
             size="small" className="search-input" variant="outlined" onChange={handleSearchChange} onKeyDown={handleKeyDown} name="search" />
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
      <Breadcrumbs sx={{paddingLeft: "10px", backgroundColor: banner_color, paddingBottom: "15px"}}>
        <StyledBreadcrumb
          id="home"
          component="a"
          href="#"
          label="Home"
          onClick={() => selectedCategory("")}
          icon={<HomeIcon fontSize="small" />}
          sx={{backgroundColor: breadcrumb_background, color: breadcrumb_text}}
        />
        {category && <StyledBreadcrumb component="a" href="#" label={category} sx={{backgroundColor: breadcrumb_background, color: breadcrumb_text}} onClick={() => selectedCategory(category)} />}
        {recipePage && <StyledBreadcrumb component="a" href="#" label={recipeTitle} sx={{backgroundColor: breadcrumb_background, color: breadcrumb_text}} />}
      </Breadcrumbs>

      </Grid>
      <Grid item xs={12}>
    <article >
      {searchTable && (<RecipeList recipeList={recipeList} selectedCard={selectEvent} selectedCategory={selectedCategory}/>)}
      {recipePage && (<Recipe recipeId={recipeId} deleteSuccess={deleteSuccess} />)}
      {addPage && (<EditorRichText editSuccess={saveSuccessEvent} cancelSuccess={cancelSuccessEvent} />)}
    </article>
    </Grid>
    <Grid item xs={12}>
      <Footer addEventHandler={addEventHandler} />
    </Grid>
    </Grid>
    <ToastContainer autoClose={1000} />
  </>
}
